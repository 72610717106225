:root {
  --gray: gray;
  --darkgray: darkgray;
  --table-background: white;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  height: 100vh;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell,
.mat-cell {
  max-width: 50vw;
  width: auto !important;
  max-width: 270px !important;
  min-width: 30px !important;
  padding-right: 24px !important;
  overflow-x: auto;
  flex-wrap: wrap;
}

/* Tabelle Farbe Container */
.mat-row:nth-child(even) {
  background-color: white;
}

/* Tabelle Farbe Container */
.mat-row:nth-child(odd) {
  background-color: whitesmoke;
}

.mat-mdc-header-row {
  /* background-color: whitesmoke !important; */
}

.mat-mdc-paginator {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: white;
  cursor: pointer;
}

.table {
  height: 100% !important;
  width: 100% !important;
}

/* Befehl select farbe Support */
.mat-form-field-type-mat-select:not(.mat-form-field-disabled)
  .mat-form-field-flex {
  background-color: white;
}

/* Eingabe-felder Support */
.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  background-color: white;
}

/* Tabelle-header schrift */
.mat-header-cell {
  color: black;
}

/* Linie bei Eingabefelder*/
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: -1px;
}

/*Farbe vom Eingabefeld*/
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 5px;
}

/*Eingabefeld verschieben*/
.mat-form-field-flex {
  margin-top: 1%;
  padding-left: 10px;
  font-size: 15px;
}

.mdc-list {
  background-color: white;
}

/* Paginator Items per page */
.ng-trigger-transformPanel {
  background-color: white;
}

/* Schrift Container Tabelle */
.mat-mdc-header-row {
  font-size: 15px;
}

.mat-mdc-table {
  background-color: white;
}

.mdc-list {
  background-color: white;
}

/*Container*/
.mdc-data-table__table {
  max-width: 50vw;
}

/* dropdown */
.mat-select-panel-wrap {
  margin-top: 24%;
}

/*Dropdown Farbe*/
.mat-menu-content {
  background-color: #3f51b5;
}

/* Dropdown Schrift-farbe*/
.mat-menu-item {
    color: white;
}